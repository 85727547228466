
import { InputTextarea } from 'primereact/inputtextarea';
import React from 'react';

export default function RenderQuestionary(props) {

    const setAnswer = (rowData, el) => {
        if (JSON.parse(window.localStorage.getItem("user")).role.label !== "ADMIN" && JSON.parse(window.localStorage.getItem("user")).role.label !== "SUPERVISOR") {
            let _formQuest = { ...props.formQuest }
            let findIndex = _formQuest.questionValue.findIndex(ele => ele.idQuestion === el.id)
            let x = {
                "idQuestion": el.id,
                "value": rowData.key,
                "note": _formQuest.questionValue.find(ele => ele.idQuestion === el.id)?.note ? _formQuest.questionValue.find(ele => ele.idQuestion === el.id).note : ""
            }
            if (findIndex >= 0) {
                _formQuest.questionValue[findIndex] = x
                props.setFormQuest(_formQuest)
            } else {
                _formQuest.questionValue.push(x)
                props.setFormQuest(_formQuest)
            }
        }
    }

    const setNoteBox = (e, el) => {
        if (JSON.parse(window.localStorage.getItem("user")).role.label !== "ADMIN" && JSON.parse(window.localStorage.getItem("user")).role.label !== "SUPERVISOR") {
            let _formQuest = { ...props.formQuest }
            let findIndex = _formQuest.questionValue.findIndex(ele => ele.idQuestion === el.id)
            let x = {
                "idQuestion": el.id,
                "value": _formQuest.questionValue.find(ele => ele.idQuestion === el.id)?.value ? _formQuest.questionValue.find(ele => ele.idQuestion === el.id).value : "",
                "note": e
            }
            if (findIndex >= 0) {
                _formQuest.questionValue[findIndex] = x
                props.setFormQuest(_formQuest)
            } else {
                _formQuest.questionValue.push(x)
                props.setFormQuest(_formQuest)
            }
        }
    }

    return (
        <div className='flex flex-col items-center gap-1'>
            <div className='w-full text-start mb-3'>{props.id + 1 + " ) "}{props.el.question}</div>
            <div className='w-full grid grid-cols-5 max-[1100px]:grid-cols-3 items-center justify-start'>
                {props.el.labels.map((element, index) => {
                    return (
                        <React.Fragment key={index}>
                            <div className='flex flex-col w-full'>
                                <div className='border border-[#00b1b14D] p-1 min-h-16 flex flex-row justify-center items-center'>{element.label}</div>
                                <div className={`border border-[#00b1b14D] p-4 ${props.formQuest?.questionValue ? (props.formQuest?.questionValue.find(ele => ele.idQuestion === props.el.id && ele.value === element.key) ? "!p-1" : "") : ""}`} onClick={() => setAnswer(element, props.el)}>{props.formQuest?.questionValue ? (props.formQuest?.questionValue.find(ele => ele.idQuestion === props.el.id && ele.value === element.key) ? "X" : "") : ""}</div>
                            </div>
                        </React.Fragment>
                    )
                })}
            </div>
            {props.el.note &&
                <div className='w-full flex flex-col items-start justify-center'>
                    <div className='mt-6 mb-3'>{props.id + 2 + " ) "}{props.el.note}</div>
                    <InputTextarea className="w-full select-none p-2 border focus:shadow-none" id='motivazione'
                        disabled={JSON.parse(window.localStorage.getItem("user")).role.label !== "ADMIN" && JSON.parse(window.localStorage.getItem("user")).role.label !== "SUPERVISOR" ? (props.formQuest?.questionValue ?
                            (props.formQuest?.questionValue.find(ele => ele.idQuestion === props.el.id)?.value === '' || !props.formQuest?.questionValue.find(ele => ele.idQuestion === props.el.id))
                            : true) : true}
                        placeholder="motivazione" value={props.formQuest?.questionValue ? (props.formQuest?.questionValue.find(ele => ele.idQuestion === props.el.id)?.note) : ""} onChange={(e) => setNoteBox(e.target.value, props.el)}
                    />
                </div>
            }
        </div>

    )
}
