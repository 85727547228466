import React, { useState, useEffect, useRef } from 'react';
import HeadPage from '../../components/headPage';
import { APIRequest } from "../../services/axios";
import { Toast } from 'primereact/toast';
import { resetPathCrumb } from '../../store/actions/actions';
import { useDispatch } from 'react-redux';
import ResponseQuest from '../../components/responseQuest';

function ValutazioneDelServizio() {
    const [resultsResponse, setResultsResponse] = useState(null)
    const [resultsQuestion, setResultsQuestion] = useState(null)
    const [resultsQuestionType, setResultsQuestionType] = useState(null)
    const [dialog1, setDialog1] = useState(false)
    const [dialog2, setDialog2] = useState(false)
    const dispatch = useDispatch()

    const toast = useRef(null);
    useEffect(() => {
        async function trovaRisultato() {
            let questionType = null
            await APIRequest('GET', 'feedbackService/questionaryType')
                .then(res => {
                    setResultsQuestionType(res.data.data);
                    questionType = res.data.data.find(el => el.label === 'SERVIZI').id
                }).catch((e) => {
                    console.log(e)
                })
            await APIRequest('GET', `feedbackService?typeId=${questionType}`)
                .then(res => {
                    setResultsQuestion(res.data.data);
                }).catch((e) => {
                    console.log(e)
                })
            await APIRequest('GET', 'feedbackService/questionResponse')
                .then(res => {
                    setResultsResponse(res.data.data);
                }).catch((e) => {
                    console.log(e)
                })
        }
        trovaRisultato();
        dispatch(resetPathCrumb())
    }, [dispatch])

    return (
        <div className="flex flex-col h-full relative">
            <Toast ref={toast} />
            <HeadPage />
            <div className={`flex-grow transition-all duration-300`}>
                <div className='flex flex-row max-[1100px]:flex-col items-start justify-around h-full'>
                    <ResponseQuest dialog1={dialog1} setDialog1={setDialog1} dialog2={dialog2} setDialog2={setDialog2} resultsQuestion={resultsQuestion} pathname={"/valutazioneDelServizio"} setResultsQuestion={setResultsQuestion} resultsResponse={resultsResponse} setResultsResponse={setResultsResponse} />
                </div>
            </div>
        </div>
    );
}

export default ValutazioneDelServizio;
