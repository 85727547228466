/* import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { MeterGroup } from 'primereact/metergroup'; */

function AppointmentDetails(props) {

    return (
        <div className="flex flex-col items-center w-full">
           {/*  <>
                <div className="flex flex-row justify-center items-center my-4 w-2/3">
                    <div className="w-full h-auto md:w-1/2 flex flex-col justify-around">
                        <div className="flex flex-row justify-around">
                            <img src="https://static-00.iconduck.com/assets.00/profile-circle-icon-2048x2048-cqe5466q.png" className="w-1/3"></img>
                        </div>
                        <div className="flex flex-row justify-around mt-4">
                            <ul className="">
                                <li className=""><i className="pi pi-user mr-3"></i><strong>Indirizzo</strong>: { }</li>
                                <li className=""><i className="pi pi pi-mobile mr-3"></i><strong>Città</strong>: { }</li>
                                <li className=""><i className="pi pi pi-credit-card mr-3"></i><strong>CAP</strong>: { }</li>
                            </ul>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2">
                        <ul className="w-full gap-2 flex flex-col">
                            <li className=""><i className="pi pi-user mr-3"></i><strong>Nome</strong>: {props.result?.idRegistry?.firstName}</li>
                            <li className=""><i className="pi pi pi-mobile mr-3"></i><strong>Cognome</strong>: {props.result?.idRegistry?.lastName}</li>
                            <li className=""><i className="pi pi pi-credit-card mr-3"></i><strong>Email</strong>: {props.result?.username}</li>
                            <li className=""><i className="pi pi pi-credit-card mr-3"></i><strong>Ruolo</strong>: {props.result?.role?.label}</li>
                            <li className=""><i className="pi pi pi-credit-card mr-3"></i><strong>Data di nascita</strong>: { }</li>
                            <li className=""><i className="pi pi pi-credit-card mr-3"></i><strong>Telefono</strong>: { }</li>
                            <li className=""><i className="pi pi pi-credit-card mr-3"></i><strong>Partita IVA</strong>: { }</li>
                        </ul>
                    </div>
                </div>
                {props.result?.role?.label === "OPERATOR" &&
                    <>
                        <hr className='w-full' />
                        <div className="w-full flex flex-row justify-around items-center my-2">
                            <div className='w-full'>
                                <MeterGroup className='customProgressBar' values={values} labelPosition="end" start={start} labelList={labelList} />
                            </div>
                        </div>
                    </>
                }
                <hr className='w-full' />
                <div className='w-full'>
                    <div className='flex flex-row justify-center mt-8'>
                        {props.result?.role?.label === "OPERATOR" &&
                            <Button className='buttonCustom !w-1/3' label='Curriculum'></Button>
                        }
                        <Button className='buttonCustom !w-1/3' label='Documenti attività'></Button>
                        <Button className='buttonCustom !w-1/3' label='Storico attività'></Button>
                    </div>
                </div>
            </> */}
        </div>
    )
}

export default AppointmentDetails;
