import React, { useEffect, useRef, useState } from 'react';
import HeadPage from '../../components/headPage';
import { Toast } from 'primereact/toast';
import { chiudimenu, resetPathCrumb } from '../../store/actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from 'react-icons/ai';
import { APIRequest, locale } from '../../services/axios';
import { Dialog } from 'primereact/dialog';

function Lezioni() {
    const [fullScreen, setFullScreen] = useState(null);
    const [courses, setCourses] = useState(null);
    const [result, setResult] = useState(null)
    const [course, setCourse] = useState(null);
    const [openDialog1, setOpenDialog1] = useState(false)
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [videoProgress, setVideoProgress] = useState(0);
    const [progressId, setProgressId] = useState(null); // Stato per memorizzare l'id del progresso
    const [showModal, setShowModal] = useState(false); // Stato per mostrare la modale
    const toast = useRef(null);
    const videoRef = useRef(null);
    const isSidebarOpen = useSelector(state => state.menu.isSidebarOpen);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(chiudimenu());
        dispatch(resetPathCrumb());
        async function trovaRisultato() {
            await APIRequest('GET', `coursesService`)
                .then(res => {
                    if (res.data.success) {
                        setCourses(res.data.data);
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile caricare la formazione.`, life: 3000 });
                    }
                }).catch((e) => {
                    console.log(e);
                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile caricare la formazione.`, life: 3000 });
                });
        }
        trovaRisultato();
    }, [dispatch]);

    const downloadCertificato = () => {
        if (course && course.certificate && course.certificate.id) {
            const url = `${locale}documentsService/download/${course.certificate.id}`;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', course.certificate.title);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.error('Certificato non disponibile o URL non trovato');
        }
    };

    const handleSelectCourse = async (el) => {
        setCourse(el);
        setSelectedLesson(el.contents[0].id);
        await fetchProgress(el.id, el.contents[0].id);
    };

    const handleLessonClick = async (lessonId) => {
        setSelectedLesson(lessonId);
        await fetchProgress(course.id, lessonId);
    };

    const fetchProgress = async (courseId, contentId) => {
        const idUser = JSON.parse(localStorage.user).id;
        await APIRequest('GET', `coursesService/progress?idUser=${idUser}&idCourse=${courseId}&idContent=${contentId}`)
            .then(res => {
                if (res.data.success && res.data.data) {
                    setVideoProgress(res.data.data.score);
                    setProgressId(res.data.data.id); // Imposta l'id del progresso
                    setShowModal(true); // Mostra la modale per chiedere se riprendere dal punto salvato
                } else {
                    setVideoProgress(0);
                    setProgressId(null); // Resetta l'id del progresso
                }
            }).catch((e) => {
                console.log(e);
                toast.current.show({ severity: 'error', summary: 'Errore', detail: `Non è stato possibile ottenere i progressi.`, life: 3000 });
            });
    };

    const handleProgress = async (status) => {
        const idUser = JSON.parse(localStorage.user).id;
        const idCourse = course.id;
        const idContent = selectedLesson;
        const score = videoRef.current.currentTime; // Ottieni il minutaggio corrente del video

        const progressData = {
            status,
            score,
        };

        if (progressId) {
            // Se esiste un progresso, fai una PUT per aggiornarlo
            await APIRequest('PUT', `coursesService/progress?id=${progressId}`, progressData)
                .then(res => {
                    if (!res.data.success) {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `Non è stato possibile salvare il progresso.`, life: 3000 });
                    }
                }).catch((e) => {
                    console.log(e);
                    toast.current.show({ severity: 'error', summary: 'Errore', detail: `Non è stato possibile salvare il progresso.`, life: 3000 });
                });
        } else {
            // Se non esiste un progresso, fai una POST per crearlo
            await APIRequest('POST', `coursesService/progress`, { ...progressData, idUser, idCourse, idContent })
                .then(res => {
                    if (res.data.success) {
                        setProgressId(res.data.data.id); // Imposta l'id del progresso creato
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `Non è stato possibile salvare il progresso.`, life: 3000 });
                    }
                }).catch((e) => {
                    console.log(e);
                    toast.current.show({ severity: 'error', summary: 'Errore', detail: `Non è stato possibile salvare il progresso.`, life: 3000 });
                });
        }
    };

    const handleResume = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = videoProgress;
        }
        setShowModal(false);
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    const deleteCourse = async () => {
        await APIRequest('DELETE', `coursesService?id=` + result.id,)
            .then(res => {
                if (res.data.success) {
                    toast.current.show({ severity: 'success', summary: 'Ottimo!', detail: `Il corso è stato cancellato correttamente`, life: 3000 });
                    setOpenDialog1(false)
                    setCourse(null);
                    setSelectedLesson(null);
                    let filter = courses.filter(el => el.id !== result.id)
                    setCourses(filter)
                    setResult(null)
                } else {
                    toast.current.show({ severity: 'error', summary: 'Errore', detail: `Non è stato possibile cancellare il corso.`, life: 3000 });
                }
            }).catch((e) => {
                console.log(e);
                toast.current.show({ severity: 'error', summary: 'Errore', detail: `Non è stato possibile cancellare il corso.`, life: 3000 });
            });
        result.contents.forEach(async element => {
            await APIRequest('DELETE', `coursesService/content?id=` + element.id,)
                .then(res => {
                    if (res.data.success) {
                        toast.current.show({ severity: 'success', summary: 'Ottimo!', detail: `Il contenuto è stato cancellato correttamente`, life: 3000 });

                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `Non è stato possibile cancellare il contenuto del corso.`, life: 3000 });
                    }
                }).catch((e) => {
                    console.log(e);
                    toast.current.show({ severity: 'error', summary: 'Errore', detail: `Non è stato possibile cancellare il contenuto del corso.`, life: 3000 });
                });
        });
    }

    const handleDialogFooter = () => {
        return (
            <React.Fragment>
                <Button label="Sì" onClick={handleResume} className="buttonCustom p-button-success mr-2" />
                <Button label="No" onClick={handleCancel} className="buttonCustom p-button-secondary" />
            </React.Fragment>
        )
    }
    const handleDialogFooter2 = () => {
        return (
            <React.Fragment>
                <Button label="Sì" onClick={() => deleteCourse()} className="buttonCustom p-button-success mr-2" />
                <Button label="No" onClick={() => { setOpenDialog1(false); setResult(null) }} className="buttonCustom p-button-secondary" />
            </React.Fragment>
        )
    }

    return (
        <div className="flex flex-col h-full relative">
            <Toast ref={toast} />
            <HeadPage />
            <div className="w-full relative h-full">
                <div className="relative w-full flex flex-row transation-all duration-200 h-full">
                    {!isSidebarOpen && !fullScreen &&
                        <div className={`w-2/12 flex flex-col border-[#00b1b14D] border-r transation-all duration-200 relative gap-2 h-full`}>
                            <ul className="w-full sticky top-0 h-[calc(100vh-100px)] overflow-y-scroll flex flex-col">
                                {courses &&
                                    <>
                                        {courses.map((el, key) => {
                                            return (
                                                <li className='hover:bg-[#00b1b14D] flex flex-row items-center justify-between p-5 px-10 border-b border-[#00b1b14D]' key={key} onClick={() => handleSelectCourse(el)}>
                                                    <div className='flex flex-col items-start w-full'>
                                                        <p className='text-start text-lg'>{el.title}</p>
                                                        <div className='flex flex-row items-center justify-start gap-2'>
                                                            <i className='pi pi-video text-sm'></i>
                                                            <p className='text-sm'>{el.total_duration} min</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Button className='flex flex-row items-center justify-center bg-[#00b1b1] py-2 text-white rounded-full' icon="pi pi-trash" onClick={() => { setOpenDialog1(true); setResult(el) }}></Button>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </>
                                }
                            </ul>
                        </div>
                    }
                    <div className={`${!isSidebarOpen && !fullScreen ? "w-10/12" : "w-full"} relative`}>
                        {course &&
                            <div className={`w-full ${fullScreen ? "h-full" : "h-2/3"} border-[#00b1b14D] border-b`}>
                                <div className='h-[93%] w-full flex flex-row justify-center items-center'>
                                    {(selectedLesson && course.contents.find(obj => obj.id === selectedLesson)?.attachments.length > 0) &&
                                        <video
                                            controls
                                            className="w-auto h-full"
                                            ref={videoRef}
                                            onPlay={() => handleProgress(1)} // Stato "in corso"
                                            onPause={() => handleProgress(1)} // Stato "in corso"
                                            onEnded={() => handleProgress(2)} // Stato "completato"
                                        >
                                            <source src={`${locale}coursesService/attachments/${course.contents.find(obj => obj.id === selectedLesson)?.attachments[0]?.filename}`} type="video/mp4" onError={(e) => console.log(e)} />
                                            Your browser does not support the video tag.
                                        </video>
                                    }
                                </div>
                                <hr className='w-full border-[#00b1b14D]' />
                                <div className="h-[7%] w-full flex flex-row justify-between items-center px-7">
                                    <div className="flex flex-row items-center space-x-4">
                                        {course.contents.map((el) => (
                                            <p
                                                key={el.id}
                                                onClick={() => handleLessonClick(el.id)}
                                                className={`text-sm font-medium cursor-pointer ${selectedLesson === el.id ? 'text-blue-500 underline' : 'text-gray-700'}`}
                                            >
                                                {el.title}
                                            </p>
                                        ))}
                                    </div>
                                    <div>
                                        {!fullScreen ? (
                                            <AiOutlineFullscreen
                                                size={30}
                                                onClick={() => setFullScreen(true)}
                                                className="cursor-pointer"
                                            />
                                        ) : (
                                            <AiOutlineFullscreenExit
                                                size={30}
                                                onClick={() => setFullScreen(false)}
                                                className="cursor-pointer"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        }
                        {!fullScreen && course &&
                            <div className='w-full h-1/3 relative'>
                                <div className='sticky top-0 h-[calc(100vh-68.5vh)] overflow-y-scroll'>
                                    <div className='px-7 py-4 '>
                                        <div className='text-2xl text-start'>{course.titolo}</div>
                                        <div className='flex flex-row gap-10'>
                                            <div className='flex flex-col items-start justify-cente pt-5'>
                                                <div className='text-lg font-bold'>{course.total_duration}</div>
                                                <div className='text-sm text-gray-400'>Durata totale</div>
                                            </div>
                                            <div className='flex flex-col items-start justify-cente pt-5'>
                                                <div className='text-lg font-bold'>{course.contents.length}</div>
                                                <div className='text-sm text-gray-400'>Lezioni totali</div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='w-full border-[#00b1b14D]' />
                                    <div className='flex flex-row px-7 py-4'>
                                        <div className='font-semibold w-1/12'>Certificati</div>
                                        <div className='inline-block w-0.5 self-stretch bg-[#00b1b14D] mx-10'></div>
                                        <div className='w-11/12 flex flex-col gap-3'>
                                            <p className='text-start'>Ottieni il certificato "{course?.certificate?.title}" completando l'intero corso</p>
                                            <Button label='Riscatta certificato' className='bg-transparent border border-[#00b1b1] text-[#00b1b1] w-3/12 p-2' onClick={() => downloadCertificato()}></Button>
                                        </div>
                                    </div>
                                    <hr className='w-full border-[#00b1b14D]' />
                                    <div className='flex flex-row px-7 py-4'>
                                        <div className='font-semibold w-1/12'>Descrizione</div>
                                        <div className='inline-block w-0.5 self-stretch bg-[#00b1b14D] mx-10'></div>
                                        <div className='text-start w-11/12'>
                                            {course.description}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Dialog
                visible={showModal}
                onHide={handleCancel}
                header="Riprendere dal punto salvato?"
                footer={handleDialogFooter}
            >
                <p>Vuoi riprendere la lezione dal punto salvato ({videoProgress.toFixed(2)} secondi)?</p>
            </Dialog>
            <Dialog
                visible={openDialog1}
                onHide={() => setOpenDialog1(false)}
                header="Cancellazione del corso"
                footer={handleDialogFooter2}
            >
                <p>Sei sicuro di voler cancellare il corso {result?.title} ?</p>
            </Dialog>
        </div>
    );
}

export default Lezioni;
