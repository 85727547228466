const initialState = {
    isSidebarOpen: true,
    role: '',
    username: '',
    path: [],
    props: {},
    theme: 'light'
};

const menuReducer = (state = initialState, action) => {
    switch (action.type) {
        case "APRI_MENU":
            return {
                ...state,
                isSidebarOpen: true
            };
        case "CHIUDI_MENU":
            return {
                ...state,
                isSidebarOpen: false
            };
        case "SET_ROLE":
            return {
                ...state,
                role: action.payload.role,
                username: action.payload.username
            };
        case "SET_THEME":
            return {
                ...state,
                theme: action.payload
            };
        case "CLEAR_AUTH":
            return {
                ...state,
                role: '',
                username: '',
                isSidebarOpen: true
            };
        case "SET_PATHCRUMB":
            return {
                ...state,
                path: Array.isArray(state.path) ? [...state.path, { "label": action.payload.label.includes("/") ? action.payload.label.split("/")[1].split(/(?=[A-Z])/).join(" ") : action.payload.label, "props": action.payload.props, "template": action.payload.template}] : [{ "label": action.payload.label.split("/")[1].split(/(?=[A-Z])/).join(" "), "props": action.payload.props, "template": action.payload.template }],
            };
        case "DEL_PATHCRUMB":
            return {
                ...state,
                path: Array.isArray(state.path) ? state.path.filter(item => item.label !== action.payload) : { "label": action.payload.split("/")[1].split(/(?=[A-Z])/).join(" ") }
            };
        case "RESET_PATHCRUMB":
            return {
                ...state,
                path: []
            };
        default:
            return state;
    }
};

export default menuReducer;
