import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import { APIRequest } from '../../services/axios';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { formatPermission, useWindowSize } from '../../utils/utils';
import { Toolbar } from 'primereact/toolbar';
import HeadPage from '../../components/headPage';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import RootForm from '../../common/rootForm';
import { resetPathCrumb } from '../../store/actions/actions';

function Configuratore() {
    const navigate = useNavigate();
    const ruolo = useSelector(state => state.menu.role);
    const [data, setData] = useState([]);
    const [roles, setRoles] = useState(null);
    const [permissions, setPermissions] = useState(null);
    const [route, setRoute] = useState(null);
    const [dialog, setDialog] = useState(false);
    const toast = useRef(null);
    const [width] = useWindowSize();
    const dispatch = useDispatch()

    useEffect(() => {
        if (ruolo !== 'ADMIN') {
            navigate('/');
        }
    }, [ruolo, navigate]);

    useEffect(() => {
        fetchPermissions();
        fetchRoles();
        fetchRoute();
        dispatch(resetPathCrumb())
    }, [dispatch]);

    const fetchRoles = async () => {
        await APIRequest('GET', 'authService/role')
            .then(res => {
                setRoles(res.data.data);
            }).catch((e) => {
                console.log(e)
            })
    };

    const fetchPermissions = async () => {
        await APIRequest('GET', 'authService/permission')
            .then(res => {
                setPermissions(res.data.data);
            }).catch((e) => {
                console.log(e)
            })
    };

    const fetchRoute = async () => {
        await APIRequest('GET', 'authService/route')
            .then(res => {
                let data = res.data.data.sort((a, b) => {
                    if (a.service < b.service) {
                        return -1
                    }
                    return <></>
                }
                )
                setRoute(data);
            }).catch((e) => {
                console.log(e)
            })
    };

    /* const EditRoute = async () => {
        await APIRequest('PUT', 'authService/route?id=' + rowData.id,)
            .then(res => {
                let data = res.data.data.sort((a, b) => { if (a.service < b.service) return -1 })
                setRoute(data);
            }).catch((e) => {
                console.log(e)
            })
    } */

    useEffect(() => {
        async function trovaRisultato() {
            if (roles && permissions && route) {
                let formattedPermissions = await formatPermission(roles, permissions, route)
                setData(formattedPermissions);
            }
        }
        trovaRisultato()
    }, [roles, permissions, route]);

    const onPermissionChange = async (rowData, role, checked) => {
        // Implementa la logica per aggiornare i permessi
        let _permissions = [...permissions]
        let id = rowData.data[role]?.id
        let idRole = rowData.data[role]?.idRole
        let idRoute = rowData.data[role]?.idRoute
        let method = id ? 'DELETE' : 'POST';
        let url = id ? `authService/permission?id=${id}` : 'authService/permission'
        let body = !id ? {
            "routeId": idRoute,
            //"userId": 2
            "roleId": idRole
        } : {}
        await APIRequest(method, url, body)
            .then(async (res) => {
                if (id) {
                    _permissions = _permissions.filter(el => el.id !== id)
                } else {
                    _permissions.push(res.data.data)
                }
                let formattedPermissions = await formatPermission(roles, _permissions, route)
                setPermissions(_permissions)
                setData(formattedPermissions);
                //toast.current.show({ severity: 'success', summary: 'Ottimo!', detail: `${`Il permesso è stato ${id ? `rimosso` : `aggiunto`} correttamente`}`, life: 3000 });
            }).catch((e) => {
                console.log(e)
                toast.current.show({ severity: 'error', summary: 'Siamo spiacenti!', detail: "Non è stato possibile eliminare il permesso", life: 3000 });
            })
    };

    const renderRoleCheckbox = (rowData, role) => {
        if (rowData.key.startsWith('method')) {
            return (
                <>
                    <Checkbox
                        checked={rowData.data[role].value}
                        onChange={(e) => onPermissionChange(rowData, role, e.checked)}
                    />
                </>
            );
        }
        return null;
    };

    const openNewRoot = () => {
        setDialog(true)
    }

    const closeNewRoot = () => {
        setDialog(false)
    }

    const roleColumns = roles ? roles.map((role, index) => (
        <Column key={index} field={role.label} header={role.label} body={(rowData) => renderRoleCheckbox(rowData, role.label)} />
    )) : [];

    const serviceTemplate = (rowData) => {
        return rowData.key.startsWith('service') ? rowData.data.service : '';
    };

    const pathTemplate = (rowData) => {
        return rowData.key.startsWith('path') ? rowData.data.path : '';
    };

    const methodTemplate = (rowData) => {
        return rowData.key.startsWith('method') ? rowData.data.method : '';
    };

    const endContent = (
        <React.Fragment>
            <Button className="buttonCustom" label={`${width > 340 ? "Nuovo" : ""}`} type="button" icon="pi pi-plus" rounded onClick={openNewRoot} tooltip="Aggiungi rotta" data-pr-tooltip="Aggiungi rotta" tooltipOptions={{ position: 'top' }} />
        </React.Fragment>
    );

    return (
        <div className="flex flex-col h-full">
            <Toast ref={toast} />
            <HeadPage />
            <div className={`flex-grow transition-all duration-300`}>
                <div className="card">
                    <Toolbar end={endContent} />
                </div>
                <div className="p-3 min-[900px]:px-10 h-auto flex flex-col justify-start items-center">
                    <TreeTable value={data} scrollable scrollHeight="400%" tableStyle={{ minWidth: '50rem' }}>
                        <Column field="service" header="Servizio" expander body={serviceTemplate} className='w-2/12'></Column>
                        <Column field="path" header="Rotta" body={pathTemplate}></Column>
                        <Column field="method" header="Metodo" body={methodTemplate}></Column>
                        {roleColumns}
                    </TreeTable>
                </div>
            </div>
            <Dialog visible={dialog} className="w-4/6 h-auto" header={"Aggiungi rotta"} onHide={() => closeNewRoot()}>
                <RootForm route={route} setRoute={setRoute} setDialog={setDialog} />
            </Dialog>
        </div>
    );
}

export default Configuratore;