import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../provider/useAuth';

function OperatorTestPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { username } = location.state || {};
  const { signout } = useAuth();

  const handleLogout = () => {
    signout(() => navigate('/', { replace: true }));
  };

  return (
    <div className="flex h-full">
      <div className={`flex-grow transition-all duration-300`}>
        <div className="h-full flex flex-col justify-center items-center bg-yellow-500">
          <h1 className="text-white text-3xl mb-4">Operator: Io sono {username}</h1>
          <button
            onClick={handleLogout}
            className="bg-white text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}

export default OperatorTestPage;
