import React, { useEffect, useRef, useState } from 'react';
import HeadPage from '../../components/headPage';
import { Toast } from 'primereact/toast';
import { chiudimenu, resetPathCrumb } from '../../store/actions/actions';
import { useDispatch } from 'react-redux';
import { APIRequest } from '../../services/axios';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import { useWindowSize } from '../../utils/utils';

function CreaLezioni() {
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [subTitle, setSubTitle] = useState("")
    const [subDescription, setSubDescription] = useState("")
    const [totalSize, setTotalSize] = useState(0);
    const [resultContent, setResultContent] = useState(null)
    const [resultCourse, setResultCourse] = useState(null)
    const [file, setFile] = useState(null)
    const [newContentFlag, setNewContentFlag] = useState(false)
    const [editContentFlag, setEditContentFlag] = useState(false)
    const [contentLesson, setContentLesson] = useState([])
    const toast = useRef(null);
    const fileRef = useRef(null);
    const [width] = useWindowSize();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(chiudimenu());
        dispatch(resetPathCrumb());
        async function trovaRisultato() {
            /* await APIRequest('GET', ``)
                .then(res => {
                    if (res.data.success) {
                        
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile caricare le lezioni`, life: 3000 });
                    }
                }).catch((e) => {
                    console.log(e);
                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile caricare le lezioni`, life: 3000 });
                }); */
        }
        trovaRisultato();
    }, [dispatch]);

    const newContent = () => {
        setSubTitle("")
        setSubDescription("")
        setFile(null)
        setTotalSize(0)
        setNewContentFlag(true)
        setEditContentFlag(false)
    }
    const delContent = () => {
        setNewContentFlag(false)
        setEditContentFlag(false)
    }

    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };

    const onTemplateUpload = (e) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Caricato' });
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        setFile(null)
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
        setFile(null)
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileRef && fileRef.current ? fileRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <div className="flex items-center gap-3 ml-auto">
                    <span>{formatedValue} / 1 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex items-center flex-wrap">
                <div className="flex items-center" style={{ width: '40%' }}>
                    <span className="flex flex-col text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex items-center flex-col">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Carica file
                </span>
            </div>
        );
    };

    const customUploadHandler = async (file) => {
        if (file) {
            setFile(file.files[0]);
        }
    };

    const handleConfirmUpload = async () => {
        const formData = new FormData();
        formData.append('file', new Blob([file], { type: file.type }), file.name);
        formData.append('originalname', file?.name);
        formData.append('path', "/attachments");
        let x = [...contentLesson]
        let y = {
            "title": title,
            "description": description,
            "contents": [
                {
                    "title": subTitle,
                    "description": subDescription,
                    "url": "/",
                    "type": file.type
                }
            ]
        }
        if (contentLesson.length > 0) {
            await APIRequest('POST', 'coursesService/content/upload?contentId=' + resultCourse.id, formData)
                .then(res => {
                    if (res.data.success) {
                        x.push({ "name": subTitle, "subDescription": subDescription, "file": file })
                        setContentLesson(x)
                        setFile(null)
                        setTotalSize(0)
                        console.log(res)
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `File non caricato`, life: 3000 });
                    }
                })
                .catch(e => {
                    console.log(e);
                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `File non caricato`, life: 3000 });
                });
        } else {
            await APIRequest('POST', 'coursesService', y)
                .then(async res => {
                    if (res.data.success) {
                        setResultCourse(res.data.data)
                        x.push({ "name": subTitle, "subDescription": subDescription, "file": file })
                        setContentLesson(x)
                        setFile(null)
                        setTotalSize(0)
                        await APIRequest('POST', 'coursesService/content/upload?contentId=' + res.data.data.id, formData)
                            .then(res => {
                                if (res.data.success) {
                                    console.log(res)
                                } else {
                                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `File non caricato`, life: 3000 });
                                }
                            })
                            .catch(e => {
                                console.log(e);
                                toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `File non caricato`, life: 3000 });
                            });
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Corso non creato`, life: 3000 });
                    }
                })
                .catch(e => {
                    console.log(e);
                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Corso non creato`, life: 3000 });
                });
        }
    }
    const handleEditUpload = async () => {
        const formData = new FormData();
        formData.append('mp4', new Blob([file], { type: 'video/mp4' }));
        formData.append('type', file?.name);
        let x = [...contentLesson]
        let findIndex = x.findIndex(el => el.name === resultContent.name)
        x[findIndex] = { "name": subTitle, "subDescription": subDescription, "file": file }
        setContentLesson(x)
        /* await APIRequest('POST', '', formData)
            .then(res => {
                console.log(res);
                toast.current.show({ severity: 'success', summary: 'Ottimo', detail: 'Il file è stato importato con successo', life: 3000 });
            })
            .catch(e => {
                console.log(e);
                toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile importare il file. Messaggio errore: ${e.response?.data !== undefined ? e.response?.data : e.message}`, life: 3000 });
            }); */
    }

    const removeContentElement = (el) => {
        const x = [...contentLesson]
        x.splice(x.indexOf(el), 1)
        setContentLesson(x)
    }

    const editContent = (el) => {
        setSubTitle(el.name)
        setSubDescription(el.subDescription)
        setFile(el.file)
        let x = { "name": el.name, "subDescription": el.subDescription, "file": el.file }
        setResultContent(x)
        setNewContentFlag(false)
        setEditContentFlag(true)
    }

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn rounded-full border border-blue-500 p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success rounded-full border border-green-700 p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger rounded-full border border-red-700 p-button-outlined' };

    return (
        <div className="flex flex-col h-full relative">
            <Toast ref={toast} />
            <HeadPage />
            <div className="w-full relative h-full">
                <div className='w-full flex flex-row justify-between px-6 py-3'>
                    <div className='w-8/12 flex flex-row justify-between'>
                        <div className='flex flex-col items-start gap-2 w-5/12 mr-14'>
                            <div className=' font-semibold uppercase'>Titolo lezione</div>
                            <InputText className="w-full select-none p-2 border focus:shadow-none" id='title' placeholder="Titolo lezione" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </div>
                        <div className='flex flex-col items-start gap-2 w-6/12 px-4'>
                            <div className=' font-semibold uppercase'>Descrizione lezione</div>
                            <InputText className="w-full select-none p-2 border focus:shadow-none" id='description' placeholder="Descrizione lezione" value={description} onChange={(e) => setDescription(e.target.value)} />
                        </div>
                    </div>
                    <div className='w-4/12 flex flex-row justify-end'>
                        {newContentFlag ?
                            <>
                                <Button className="buttonCustom !py-1" icon="pi pi-times" label="Chiudi" onClick={() => delContent()}></Button>
                            </>
                            :
                            <>
                                <Button className="buttonCustom !py-1" icon="pi pi-plus" label={`${width > 340 ? "Nuovo" : ""}`} onClick={() => newContent()}></Button>
                            </>
                        }
                    </div>
                </div>
                <hr className='border border-[#00b1b14D] mt-5' />
                {contentLesson.length > 0 &&
                    <>
                        <div className='flex flex-row justify-start w-full px-10 pt-3 font-semibold uppercase'>Contenuto del corso</div>
                        <div className='flex flex-row flex-wrap gap-3 w-full px-10 max-h-[15rem] overflow-y-scroll boxshadow-bottom pt-4 pb-14'>
                            {contentLesson.map((el, key) => {
                                return (
                                    <React.Fragment key={key}>
                                        <div className='flex flex-row items-center gap-3 border border-[#00b1b14D] p-5 px-2 hover:bg-[#00b1b14D]' onClick={() => editContent(el)}>
                                            {(key + 1) + ") "}
                                            {el.name}
                                            <i className='pi pi-times buttonCustom !p-1 !rounded-full' onClick={() => removeContentElement(el)}></i>
                                        </div>
                                    </React.Fragment>
                                )
                            })
                            }
                        </div>
                        <hr className='border border-[#00b1b14D] mb-5' />
                    </>
                }
                {(editContentFlag || newContentFlag) &&
                    <div className='flex flex-col gap-6 mt-3'>
                        <div className='flex flex-row px-6'>
                            <div className='flex flex-col items-start gap-2 w-6/12 px-4'>
                                <div className=' font-semibold uppercase'>Titolo contenuto</div>
                                <InputText className="w-full select-none p-2 border focus:shadow-none" id='subTitle' placeholder="Titolo contenuto" value={subTitle} onChange={(e) => setSubTitle(e.target.value)} />
                            </div>
                            <div className='flex flex-col items-start gap-2 w-6/12 px-4'>
                                <div className=' font-semibold uppercase'>Descrizione contenuto</div>
                                <InputText className="w-full select-none p-2 border focus:shadow-none" id='subDescription' placeholder="Descrizione contenuto" value={subDescription} onChange={(e) => setSubDescription(e.target.value)} />
                            </div>
                        </div>
                        <div className='flex flex-row justify-center w-full'>
                            <div className='flex flex-col w-6/12 px-10'>
                                <div className='w-full text-start py-2 flex flex-row items-center gap-2 text-md font-semibold'>Seleziona il file da caricare</div>
                                <Tooltip target=".custom-choose-btn" content="Seleziona" position="bottom" />
                                <Tooltip target=".custom-upload-btn" content="Carica" position="bottom" />
                                <Tooltip target=".custom-cancel-btn" content="Rimuovi" position="bottom" />

                                <FileUpload ref={fileRef} name="demo[]" url="/api/upload" accept="*" maxFileSize={10000000}
                                    onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                                    headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                                    chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions}
                                    customUpload uploadHandler={customUploadHandler}
                                />
                                <div className='w-full text-start text-sm p-2'>Solo file con estensione .pdf .mp4</div>
                            </div>
                        </div>
                    </div>
                }
                {(editContentFlag || newContentFlag) &&
                    <>
                        <hr className='border border-[#00b1b14D] my-5' />
                        <div className="w-full flex flex-row items-center justify-center">
                            {editContentFlag ?
                                <Button type="button" label="Aggiorna contenuto" icon="pi pi-pencil" className="!w-3/12 buttonCustom" disabled={!title || !description || !subTitle || !subDescription || !file} onClick={handleEditUpload} />
                                :
                                <Button type="button" label="Aggiungi contenuto" icon="pi pi-plus" className="!w-3/12 buttonCustom" disabled={!title || !description || !subTitle || !subDescription || !file} onClick={handleConfirmUpload} />
                            }
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default CreaLezioni;
