import React, { useEffect, useState } from "react";
import { useAuth } from "./useAuth";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { APIRequest } from "../services/axios";

export function RequireAuth({ children, token }) {
    let auth = useAuth();
    let location = useLocation();
    const role = useSelector(state => state.menu.role);
    const [available, setAvailable] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function trovaRisultato() {
            try {
                const res = await APIRequest('GET', 'authService/permission');
                let filteredRoles = res?.data?.data?.filter(el => el?.route?.path === location?.pathname).map(el => el?.role?.label)?.find(el => el === role);
                setAvailable(filteredRoles);
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        }
        trovaRisultato();
    }, [location.pathname, role]);

    if (!auth.token && !token) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!available) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return children;
}