import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { FaRegStar, FaStar, FaStarHalfAlt } from 'react-icons/fa';

function CustomDataTable(props) {

    const locale = 'it-IT'
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
    }

    const firstName = (rowData) => {
        return (
            <span>{rowData?.idRegistry?.firstName}</span>
        );
    }
    //FirstName e nome sono diversi è normale, vengono utilizzati in due tabelle diverse (vale lo stesso per lastName e cognome)
    const nome = (rowData) => {
        return (
            <span>{rowData?.idUser?.idRegistry?.firstName}</span>
        );
    }
    const lastName = (rowData) => {
        return (
            <span>{rowData?.idRegistry?.lastName}</span>
        );
    }
    const cognome = (rowData) => {
        return (
            <span>{rowData?.idUser?.idRegistry?.lastName}</span>
        );
    }
    const username = (rowData) => {
        return (
            <span>{rowData?.idUser?.username}</span>
        );
    }
    const role = (rowData) => {
        return (
            <span>{rowData?.idUser?.role?.label}</span>
        );
    }
    const contestoTemplate = (rowData) => {
        if (rowData?.body.substring().length > 10) {
            return (
                <span>{rowData?.body.substring(0, 10) + "..."}</span>
            );
        } else {
            return (
                <span>{rowData?.body}</span>
            );
        }
    }
    const dataFormat = (rowData) => {
        return (
            <span>{
                new Intl.DateTimeFormat("it-IT", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                }).format(new Date(rowData?.idDocument ? rowData?.idDocument?.created_at : rowData?.createAt))
            }</span>
        );
    }
    const roleTemplateHandler = (rowData) => {
        if (rowData?.jobPosition) {
            return (
                <span>{rowData?.jobPosition?.title}</span>
            );
        } else {
            return (
                <span>{rowData?.role?.label}</span>
            );
        }
    };

    const statusTemplate = (rowData) => {
        if (rowData.status) {
            if (rowData.status.label === "Test") {
                return (
                    <span className='bg-red-600 rounded p-2 text-white font-semibold uppercase'>{rowData?.status?.label}</span>
                )
            } else {
                return ("")
            }
        }
    }

    const userId = (rowData) => {
        console.log(rowData)
        return (rowData.idUser)
    }

    const percentuale = (rowData) => {
        let x = 0
        rowData.questionValue.forEach(element => {
            x = x + element.value
        });
        let percent = (x / (rowData.questionValue.length * 5)) * 100
        return (percent.toFixed(2) + "%")
    }
    const startTemplate = (rowData) => {
        return (
            <span>{new Date(rowData?.start).toLocaleDateString(locale, options)}</span>
        );
    };
    const endTemplate = (rowData) => {
        return (
            <span>{new Date(rowData?.end).toLocaleDateString(locale, options)}</span>
        );
    };
    const dataResponse = (rowData) => {
        return (
            <span>{new Date(rowData?.createAt).toLocaleDateString(locale, options)}</span>
        );
    };

    const handlers = {
        roleTemplate: roleTemplateHandler,
        firstName: firstName,
        nome: nome,
        lastName: lastName,
        cognome: cognome,
        startTemplate: startTemplate,
        endTemplate: endTemplate,
        statusTemplate: statusTemplate,
        dataFormat: dataFormat,
        username: username,
        role: role,
        contestoTemplate: contestoTemplate,
        userId: userId,
        percentuale: percentuale,
        dataResponse: dataResponse,
    };

    const actionBodyTemplate = (rowData) => {
        let _actionFields = [...props.actionFields]
        return (
            <>
                {_actionFields.map((el, key) => {
                    return (
                        <React.Fragment key={key}>
                            <Button className='rounded-full p-2 mr-1 w-auto bg-[#00b1b1] text-white' key={key} tooltip={el.name} tooltipOptions={{ position: "top" }} onClick={() => el.handler(rowData)}><i className={el.icon.props.className}></i></Button>
                        </React.Fragment>
                    )
                }
                )}
            </>
        );
    }

    return (
        <div className='card w-full'>
            <DataTable
                className=""
                ref={props.refer}
                value={props.value}
                loading={props.loading}
                dataKey={props.dataKey}
                header={props.showHeader ? props.header : null}
                paginator
                rows={props.rows ? props.rows : 5}
                rowsPerPageOptions={[5, 10, 25, 50]}
                emptyMessage='Non ci sono elementi disponibili al momento'
                rowGroupMode={props.rowGroupMode}
                groupRowsBy={props.groupRowsBy}
                sortMode={props.sortMode}
                sortField={props.sortField}
                sortOrder={props.sortOrder}
                expandableRowGroups={props.expandableRowGroups}
                expandedRows={props.expandedRows}
                onRowToggle={props.onRowToggle}
                rowGroupHeaderTemplate={props.rowGroupHeaderTemplate}
                rowGroupFooterTemplate={props.rowGroupFooterTemplate}
            >
                {props.fields?.map((el) => (
                    <Column
                        key={el.field}
                        className={el.className}
                        field={el.field}
                        header={el.showHeader ? el.header : null}
                        sortable={el.sortable}
                        body={el.body !== undefined ? (rowData) => handlers[el.body](rowData) : null}
                        headerStyle={el.headerStyle}
                    />
                ))}
                {props.actionFields && (
                    <Column field='action' key={Math.random()} body={(rowData) => actionBodyTemplate(rowData)} exportable={false}></Column>
                )}
            </DataTable>
        </div>
    );
}

export default CustomDataTable;
