// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBffz5e4I4XLQBsbdfLTphLXtPXveJk7XQ",
  authDomain: "salus-93329.firebaseapp.com",
  projectId: "salus-93329",
  storageBucket: "salus-93329.appspot.com",
  messagingSenderId: "494822458062",
  appId: "1:494822458062:web:4df1c96078e5fcdbda869f",
  measurementId: "G-1YQSEZFDBC"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// Messaging service
export const messaging = getMessaging(firebaseApp);