import React, { useState, useEffect, useRef } from 'react';
import HeadPage from '../../components/headPage';
import { APIRequest } from "../../services/axios";
import { Toast } from 'primereact/toast';
import FeedbackFrame from '../../components/feedbackFrame';
import { resetPathCrumb } from '../../store/actions/actions';
import { useDispatch } from 'react-redux';

function Feedback() {
    const [resultDialog1, setResultDialog1] = useState(true)
    const [resultsQuestion, setResultsQuestion] = useState(null)
    const dispatch = useDispatch()

    const toast = useRef(null);
    useEffect(() => {
        async function trovaRisultato() {
            await APIRequest('GET', 'feedbackService')
                .then(res => {
                    setResultsQuestion(res.data.data);
                }).catch((e) => {
                    console.log(e)
                })
            /* await APIRequest('GET', 'feedbackService/question')
                .then(res => {
                    setResultsQuestion(res.data.data);
                }).catch((e) => {
                    console.log(e)
                }) */
        }
        trovaRisultato();
        setResultDialog1(true)
        dispatch(resetPathCrumb())
    }, [dispatch])

    return (
        <div className="flex flex-col h-full relative bg-stone-100">
            <Toast ref={toast} />
            <HeadPage />
            {resultsQuestion?.length > 0 &&
                <div className={`flex-grow transition-all duration-300`}>
                    <div className='flex flex-row max-[1100px]:flex-col items-start justify-around h-full'>
                        <FeedbackFrame resultsQuestion={resultsQuestion} setResultsQuestion={setResultsQuestion} resultDialog1={resultDialog1} setResultDialog1={setResultDialog1} />
                    </div>
                </div>
            }
        </div>
    );
}

export default Feedback;
