import React, { useEffect, useRef, useState } from 'react';
import HeadPage from '../../components/headPage';
import { Toast } from 'primereact/toast';
import { APIRequest } from "../../services/axios";
import { resetPathCrumb, setPathCrumb } from '../../store/actions/actions';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button } from 'primereact/button';
import CustomDataTable from '../../components/customDataTable';
import { handleDialogAndBreadcrumbs, useWindowSize } from '../../utils/utils';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';

function Notifiche() {
    const dt = useRef(null);
    const toast = useRef(null);
    const [results, setResults] = useState(null)
    const [results2, setResults2] = useState(null)
    const [result, setResult] = useState(null)
    const [dialog, setDialog] = useState(false);
    const [dialog2, setDialog2] = useState(false);
    const [title, setTitle] = useState("")
    const [toUser, setToUser] = useState(null)
    const [contenuto, setContenuto] = useState("")
    const dispatch = useDispatch()
    const [width] = useWindowSize();

    useEffect(() => {
        async function trovaRisultato() {
            await APIRequest('GET', 'notificationService')
                .then(res => {
                    setResults(res.data.data);
                }).catch((e) => {
                    console.log(e)
                })
            await APIRequest('GET', 'authService/user')
                .then(res => {
                    let _results2 = res.data.data.map(el => ({
                        value: { email: el.username, idUser: el.id, idRegistry: el.idRegistry.id },
                        label: el.username
                    }));
                    setResults2(_results2);
                }).catch((e) => {
                    console.log(e)
                })
        }
        trovaRisultato();
        dispatch(resetPathCrumb())
    }, [dispatch])

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const manageUser = (rowData, isNew) => {
        setDialog2(true)
        setResult(!isNew ? rowData : null)
        dispatch(setPathCrumb(
            {
                "label": "Invia notifica",
                "props": {
                    dialog: true,
                    result: !isNew ? rowData : null,
                    flag: true
                },
                "template": () => <NavLink to={"/notifiche"} onClick={() => { setDialog2(true) }}>Invia notifica</NavLink>
            }
        ))
    }

    const openDialog = (rowData) => {
        setDialog(true)
        dispatch(setPathCrumb(
            {
                "label": "Info notifica",
                "props": {
                    results: results,
                    dialog: true,
                    result: rowData,
                    flag: false
                },
                "template": () => <NavLink to={"/notifiche"} onClick={() => { setDialog(true) }}>Info notifica</NavLink>
            }
        ))
        setResult(rowData)
    }

    const indietro = () => {
        handleDialogAndBreadcrumbs(setDialog2, dispatch)
    }
    const indietro2 = () => {
        handleDialogAndBreadcrumbs(setDialog, dispatch)
    }

    const handleSaveUser = async () => {
        let body = {
            fcmToken: toUser,
            title: title,
            body: contenuto
        }
        await APIRequest("POST", "notificationService/", body)
            .then(res => {
                let _results = [...results]
                res.data.forEach(element => {
                    let x = {
                        body: element.data.newNotification.body,
                        createAt: element.data.newNotification.createAt,
                        deleteAt: element.data.newNotification.deleteAt,
                        id: element.data.newNotification.id,
                        idUser: element.data.idUser,
                        title: element.data.newNotification.title,
                        updateAt: element.data.newNotification.updateAt,
                    }
                    _results.unshift(x)
                    setResults(_results)
                });
                setDialog2(false)
            }).catch((e) => {
                console.log(e)
                toast.current.show({ severity: 'error', summary: 'Siamo spiacenti!', detail: "Non è stato possibile inviare la notifica", life: 3000 });
            })
    }

    const fields = [
        {
            field: "username",
            header: "Username",
            body: "username",
            sortable: true,
            showHeader: true,
        },
        {
            field: "role",
            header: "Ruolo",
            body: "role",
            sortable: true,
            showHeader: true,
        },
        {
            field: "title",
            header: "Titolo",
            sortable: true,
            showHeader: true,
        },
        {
            field: "body",
            header: "Contesto",
            body: "contestoTemplate",
            sortable: true,
            showHeader: true,
        },
        {
            field: "createAt",
            header: "Data",
            body: "dataFormat",
            showHeader: true,
        },
    ];
    const actionFields = [
        {
            name: "Info",
            icon: <i className="pi pi-eye" />,
            handler: openDialog,
        },
    ];

    const header = (
        <div className="flex flex-row justify-between items-center">
            <Button className="buttonCustom" type="button" label={`${width > 340 ? "Esporta CSV" : ""}`} icon="pi pi-download" rounded onClick={() => exportCSV()} />
            <Button className="buttonCustom" type="button" label="Nuova" icon="pi pi-plus" rounded onClick={(rowData) => manageUser(rowData, true)} tooltip="Invia notifica" data-pr-tooltip="Invia notifica" tooltipOptions={{ position: 'top' }} />
        </div>
    );

    return (
        <div className="flex flex-col h-full relative">
            <Toast ref={toast} />
            <HeadPage setDialog={setDialog} />
            {dialog2 ?
                <div className={`flex flex-col h-full min-[900px]:px-10`}>
                    <div className='flex flex-row justify-start p-5'>
                        <Button className='buttonCustom' label='Indietro' onClick={() => indietro()}></Button>
                    </div>
                    <div className="flex flex-col items-center h-full">
                        <div className='w-2/3 flex flex-row justify-between gap-2'>
                            <div className="w-2/4">
                                <label htmlFor="title" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                                    <i className="pi pi-user mr-3"></i>Titolo
                                </label>
                                <InputText className="w-full select-none p-2 border focus:shadow-none" id='title' placeholder="Titolo" value={title} onChange={(e) => setTitle(e.target.value)} />
                            </div>
                            <div className="w-1/4">
                                <label htmlFor="toUser" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                                    <i className="pi pi-user mr-3"></i>Invia a
                                </label>
                                <MultiSelect className="w-full select-none border focus:shadow-none" id='toUser' placeholder="Invia a" value={toUser} options={results2} onChange={(e) => setToUser(e.target.value)} filter filterBy="label" />
                            </div>
                        </div>
                        <div className="w-2/3 h-2/6">
                            <label htmlFor="body" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                                <i className="pi pi-user mr-3"></i>Contenuto
                            </label>
                            <InputTextarea className="w-full !h-5/6 select-none p-2 border focus:shadow-none" id='body' placeholder="Contenuto" value={contenuto} onChange={(e) => setContenuto(e.target.value)} />
                        </div>
                        <div className="flex justify-center items-end">
                            <Button disabled={title === "" || (!toUser || toUser?.length <= 0) || contenuto === ""}
                                className='buttonCustom' tooltipOptions={{ showDelay: 1000, hideDelay: 300 }} label="Salva" onClick={() => handleSaveUser()} />
                        </div>
                    </div>
                </div>
                :
                <>
                    {dialog ?
                        <div className={`flex flex-col h-full min-[900px]:px-10`}>
                        <div className='flex flex-row justify-start p-5'>
                            <Button className='buttonCustom' label='Indietro' onClick={() => indietro2()}></Button>
                        </div>
                        <div className="flex flex-col items-center h-full">
                            <div className='w-2/3 flex flex-row justify-between gap-2'>
                                <div className="w-2/4">
                                    <span className="w-full p-2 focus:shadow-none flex flex-row items-start font-bold my-2">{result.title}</span>
                                </div>
                            </div>
                            <div className="w-2/3 h-2/6">
                                <InputTextarea className="w-full !h-5/6 p-2 border focus:shadow-none" id='body' placeholder="Contenuto" value={result.body} disabled />
                                <span className='flex flex-row items-start justify-end text-sm gap-1'><p>Sent by : </p><b>{result?.idUser?.username}</b></span>
                            </div>
                        </div>
                    </div>
                        :
                        <div className="min-[900px]:px-10 h-full flex flex-col justify-start items-center">
                            <CustomDataTable
                                dataKey="id"
                                rows={10}
                                loading={!results}
                                refer={dt}
                                value={results}
                                actionFields={actionFields}
                                fields={fields}
                                showHeader={true}
                                header={header}
                            />
                        </div>
                    }
                </>
            }
        </div>
    );
}

export default Notifiche;
