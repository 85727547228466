import axios from "axios";

export const locale = 'https://salus.vxdigital.it/api/'

export const baseURL = locale;

export const APIRequest = (method, path, data) => {

    return new Promise((resolve, reject) => {
        if (typeof method === "string" && typeof path === "string") {
            axios({
                timeout: 60 * 60 * 1000,
                method: method,
                url: baseURL + path,
                data: data,
                headers: { auth: localStorage.token, accept: "application/json" },

            })
                .then((res) => {
                    resolve(res);
                })
                .catch((e) => {
                    console.log(e)
                    if (e.response !== undefined) {
                        if (e.response.data.message === "invalid password" || e.response.data.message === "username not found") {
                            alert(e.response.data.message);
                        } else if (e.response.status === 401) {
                            localStorage.setItem("token", "");
                            localStorage.setItem("user", "");
                            setTimeout(() => {
                                window.location.pathname = "/";
                            }, 4000);
                        } else if (e.response.status === 500) {
                            window.sessionStorage.setItem("Error", JSON.stringify(e.response.data.message !== undefined ? e.response.data.message : e.response.data));
                            window.sessionStorage.setItem("CodeError", e.response.status);
                            setTimeout(() => {
                                window.location.pathname = "/";
                            }, 4000);
                        }
                    }
                    reject(e);
                });
        }
    });
};