import { useLayoutEffect, useState } from "react";
import { delPathCrumb } from "../store/actions/actions";

export const formatPermission = (roles, permissions, route) => {
    let services = route.map(el => el.service);
    services = [...new Set(services)];
    let formattedPermissions = services.map((service, serviceIndex) => {
        let serviceNode = {
            key: `service-${service}`,
            data: { service },
            children: []
        };

        let filter = route.filter(el => el.service === service);
        if (filter.length > 0) {
            let paths = filter.map(el => el.path);
            paths = [...new Set(paths)];
            for (const path of paths) {
                let methods = filter.filter(el => el.path === path).map(obj => obj.method);
                let pathNode = {
                    key: `path-${service}-${path}`,
                    data: { path },
                    children: []
                };

                for (const method of methods) {
                    let perm = permissions.filter(el => el.route.service === service && el.route.path === path && el.route.method === method);
                    let permissionRoles = perm.map(el => el.role.label);
                    let activeRoles = roles.map(el => el.label);
                    let methodNode = {
                        key: `method-${service}-${path}-${method}`,
                        data: { method }
                    };
                    activeRoles.forEach(rol => {
                        let idRole = roles.find(el => el.label === rol).id
                        let idRoute = route.find(el => el.path === path && el.method === method && el.service === service).id
                        let id = permissions.find(el => el.route.service === service && el.route.path === path && el.route.method === method && el.role.label === rol)?.id
                        methodNode.data[rol] = { value: permissionRoles.includes(rol), id, idRole, idRoute };
                    });

                    pathNode.children.push(methodNode);
                }
                serviceNode.children.push(pathNode);
            }
        }
        return serviceNode;
    });
    return formattedPermissions;
}

export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

// breadcrumbUtils.js
export const handleDialogAndBreadcrumbs = (setDialog, dispatch) => {
    if (setDialog) {
        setDialog(false);
    }
    const path = window.location.pathname;
    switch (path) {
        case "/operatori":
            dispatch(delPathCrumb("Nuovo operatore"));
            break;
        case "/pazienti":
            dispatch(delPathCrumb("Nuovo paziente"));
            break;
        case "/hr":
            dispatch(delPathCrumb("Nuovo HR"));
            break;
        case "/contabili":
            dispatch(delPathCrumb("Nuovo contabile"));
            break;
        case "/supervisore":
            dispatch(delPathCrumb("Nuovo supervisore"));
            break;
        case "/segretari":
            dispatch(delPathCrumb("Nuovo segretario"));
            break;
        case "/candidati":
            dispatch(delPathCrumb("Nuovo candidato"));
            break;
        case "/conversazioni":
            dispatch(delPathCrumb("Conversazioni"));
            break;
        case "/notifiche":
            dispatch(delPathCrumb("Invia notifica"));
            dispatch(delPathCrumb("Info notifica"));
            break;
        case "/valutazioneDellaStruttura":
            dispatch(delPathCrumb("Info feedback"));
            break;
        case "/valutazioneDelServizio":
            dispatch(delPathCrumb("Info feedback"));
            break;
        case "/questionariValutazioneDellaStruttura":
            dispatch(delPathCrumb("Crea questionario"));
            dispatch(delPathCrumb("Info questionario"));
            break;
        case "/questionariValutazioneDelServizio":
            dispatch(delPathCrumb("Crea questionario"));
            dispatch(delPathCrumb("Info questionario"));
            break;
        case "/creaLezioni":
            dispatch(delPathCrumb("Crea Lezioni"));
            break;
        case "/formazione":
            dispatch(delPathCrumb("Lezioni"));
            break;
        default:
            break;
    }
    dispatch(delPathCrumb("Info Profilo"));
};

export const capitalizePathName = (path) => {
    const formattedPath = path.split("/")[1].split(/(?=[A-Z])/).join(" ");
    return formattedPath.charAt(0).toUpperCase() + formattedPath.slice(1);
};
