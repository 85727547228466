
import React, { useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { APIRequest } from '../services/axios';
import { Toast } from 'primereact/toast';
import { useDispatch } from 'react-redux';
import { handleDialogAndBreadcrumbs } from '../utils/utils';

export default function UserForm(props) {
    const [firstName, setFirstName] = useState(props?.result ? props?.result?.idRegistry?.firstName : '');
    const [lastName, setLastName] = useState(props?.result ? props?.result?.idRegistry?.lastName : '');
    const [cellnum, setCellNum] = useState(props?.result ? props?.result?.idRegistry?.tel.split('/')[0] : '');
    const [tellNum, setTellNum] = useState(props?.result ? props?.result?.idRegistry?.tel.split('/')[1] : '');
    const [pIva, setPIva] = useState(props?.result ? props?.result?.idRegistry?.pIva : '');
    const [city, setCity] = useState(props?.result ? props?.result?.idRegistry?.city : '');
    const [email, setEmail] = useState(props?.result ? props?.result?.idRegistry?.email : '');
    const [address, setAddress] = useState(props?.result ? props?.result?.idRegistry?.address : '');
    const [cap, setCap] = useState(props?.result ? props?.result?.idRegistry?.cap : '');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch()
    const toast = useRef(null);

    const handleSaveUser = async () => {
        let body = password ? {
            idRegistry: {
                firstName: firstName,
                lastName: lastName,
                pIva: pIva,
                city: city,
                address: address,
                cap: cap,
                email: email,
                telnum: tellNum,
                cellnum: cellnum,
                isValid: true
            },
            password: password,
            username: email,
            role: props.idRole
        } : {
            idRegistry: {
                firstName: firstName,
                lastName: lastName,
                pIva: pIva,
                city: city,
                address: address,
                cap: cap,
                email: email,
                telnum: tellNum,
                cellnum: cellnum,
                isValid: true
            },
            username: email,
            role: props.idRole
        }
        let _results = [...props.results]
        let method = props?.result ? 'PUT' : 'POST';
        let url = props?.result ? `authService/user-registry?id=${props?.result?.id}&idRegistry=${props?.result?.idRegistry?.id}` : 'authService/user-registry'
        await APIRequest(method, url, body)
            .then(res => {
                let findIndex = _results.findIndex(el => el.id === res.data.data.id)
                if (findIndex >= 0) {
                    _results[findIndex] = res.data.data
                } else {
                    _results.push(res.data.data)
                    handleDialogAndBreadcrumbs(props.setDialog, dispatch);
                }
                setPassword("")
                props.setResults(_results)
                toast.current.show({ severity: 'success', summary: 'Ottimo!', detail: `${`L'utente è stato ${props?.result ? `modificato` : `aggiunto`} correttamente`}`, life: 3000 });
            }).catch((e) => {
                console.log(e)
                toast.current.show({ severity: 'error', summary: 'Siamo spiacenti!', detail: "Non è stato possibile eliminare l'utente", life: 3000 });
            })
    }

    return (
        <div className="card">
            <Toast ref={toast} />
            <div className="grid grid-cols-3 max-[670px]:grid-cols-2 max-[520px]:grid-cols-1 gap-3 mb-4">
                <div className="mt-3">
                    <label htmlFor="firstName" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                        <i className="pi pi-user mr-3"></i>Nome
                    </label>
                    <InputText className="w-full select-none p-2 border focus:shadow-none" id='firstName' placeholder="Nome" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                </div>
                <div className="mt-3">
                    <label htmlFor="lastName" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                        <i className="pi pi-user mr-3"></i>Cognome
                    </label>
                    <InputText className="w-full select-none p-2 border focus:shadow-none" id='lastName' placeholder="Cognome" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                </div>
                <div className="mt-3">
                    <label htmlFor="pIva" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                        <i className="pi pi-credit-card mr-3"></i>p. IVA / Cod. fiscale
                    </label>
                    <InputText className="w-full select-none p-2 border focus:shadow-none" id='pIva' placeholder="Partita IVA / Codice fiscale" value={pIva} onChange={(e) => setPIva(e.target.value)} />
                </div>
                <div className="mt-3">
                    <label htmlFor="address" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                        <i className="pi pi-map-marker mr-3"></i>Indirizzo
                    </label>
                    <InputText className='w-full select-none p-2 border focus:shadow-none' id='address' placeholder="Indirizzo" value={address} onChange={(e) => setAddress(e.target.value)} />
                </div>
                <div className="mt-3">
                    <label htmlFor="city" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                        <i className="pi pi-map mr-3"></i>Città
                    </label>
                    <InputText className='w-full select-none p-2 border focus:shadow-none' id='city' placeholder="Città" value={city} onChange={(e) => setCity(e.target.value)} />
                </div>
                <div className="mt-3">
                    <label htmlFor="cap" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                        CAP
                    </label>
                    <InputText className='w-full select-none p-2 border focus:shadow-none' id='cap' placeholder="CAP" value={cap} onChange={(e) => setCap(e.target.value)} />
                </div>
                <div className="mt-3">
                    <label htmlFor="cellnum" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                        <i className="pi pi-mobile mr-3"></i>Cellulare
                    </label>
                    <InputText className="w-full select-none p-2 border focus:shadow-none" id='cellnum' placeholder="Cellulare" value={cellnum} onChange={(e) => setCellNum(e.target.value)} />
                </div>
                <div className="mt-3">
                    <label htmlFor="tellNum" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                        <i className="pi pi-phone mr-3"></i>Telefono
                    </label>
                    <InputText className="w-full select-none p-2 border focus:shadow-none" id='tellNum' placeholder="Telefono" value={tellNum} onChange={(e) => setTellNum(e.target.value)} />
                </div>
                <div className="mt-3">
                    <label htmlFor="email" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                        <i className="pi pi-at mr-3"></i>Email
                    </label>
                    <InputText className="w-full select-none p-2 border focus:shadow-none" id="email" keyfilter="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="mt-3">
                    <label htmlFor="password" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                        <i className="pi pi-key mr-3"></i>Password
                    </label>
                    <div className="card flex justify-content-center">
                        <Password className='w-full select-none p-2 border focus:shadow-none' id='password' placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}
                            promptLabel="Scegli una password" weakLabel="Fragile" mediumLabel="Media" strongLabel="Sicura"
                            toggleMask
                        />
                    </div>
                </div>
            </div>
            <div className="flex justify-center items-end">
                <Button disabled={firstName === "" || lastName === "" || pIva === "" || address === "" || city === "" || cap === "" || cellnum === "" || tellNum === "" || email === "" || password === ""}
                    className='buttonCustom ' tooltipOptions={{ showDelay: 1000, hideDelay: 300 }} label="Salva" onClick={() => handleSaveUser()} />
            </div>
        </div>
    )
}
