import { Outlet, useLocation } from "react-router-dom";
import { Sidebar } from "./sidebar";
import { useSelector } from "react-redux";

function Layout() {
    const location = useLocation();
    const role = useSelector(state => state.menu.role);

    const hideSidebarPaths = ["/"]; // Aggiungi qui tutti i percorsi in cui vuoi nascondere la sidebar

    const shouldShowSidebar = role && !hideSidebarPaths.includes(location.pathname);

    return (
        <div className="flex flex-row max-[900px]:flex-col relative">
            {shouldShowSidebar &&
                <div className={`sticky top-0 z-30 min-[900px]:h-screen min-[900px]:!h-[calc(100vh-10px)]`}>
                    <Sidebar theme="light" />
                </div>
            }
            <div className="w-screen">
                <Outlet />
            </div>
        </div>
    )
}

export default Layout;
