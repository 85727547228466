export const aprimenu = () => ({
    type: "APRI_MENU",
});

export const chiudimenu = () => ({
    type: "CHIUDI_MENU",
});
export const setRole = (role, username) => {
    return {
        type: "SET_ROLE",
        payload: { role, username }
    }
}
export const setTheme = (theme) => ({
    type: "SET_THEME",
    payload: theme
});
export const setPathCrumb = (path) => ({
    type: "SET_PATHCRUMB",
    payload: path
});
export const delPathCrumb = (path) => ({
    type: "DEL_PATHCRUMB",
    payload: path
});
export const resetPathCrumb = () => ({
    type: "RESET_PATHCRUMB"
});
export const clearAuth = () => ({
    type: "CLEAR_AUTH"
});
