import React, { useEffect, useRef } from 'react';
import HeadPage from '../../components/headPage';
import { Toast } from 'primereact/toast';
import { resetPathCrumb } from '../../store/actions/actions';
import { useDispatch } from 'react-redux';

function Rendicontazione() {
    const toast = useRef(null);
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetPathCrumb())
    }, [dispatch])
    return (
        <div className="flex flex-col h-full relative">
            <Toast ref={toast} />
            <HeadPage />
            <div className={`flex-grow transition-all duration-300`}>

            </div>
        </div>
    );
}

export default Rendicontazione;
