import React, { useEffect, useRef, useState } from 'react';
import HeadPage from '../../components/headPage';
import { Toast } from 'primereact/toast';
import { APIRequest } from '../../services/axios';
import { resetPathCrumb } from '../../store/actions/actions';
import { useDispatch } from 'react-redux';
import BackgroundAnimation from '../../components/backgroundAnimation';
import { Tooltip } from 'primereact/tooltip';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';

function PageCandidato() {
    const toast = useRef(null);
    const fileRef = useRef(null);
    const [totalSize, setTotalSize] = useState(0);
    const [fileBuffer, setFileBuffer] = useState([]); // Cambiato a un array per più file
    const [resultsTypeDocs, setResultsTypeDocs] = useState(null);
    const [fileUpload, setFileUpload] = useState(null);
    const [titleDoc, setTitleDoc] = useState([]);
    const [resultsDocs, setResultDocs] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        async function trovaRisultato() {
            await APIRequest('GET', 'documentsService/documentType')
                .then((res) => {
                    if (res.data.success) {
                        setResultsTypeDocs(res.data.data);
                    } else {
                        toast.current.show({ severity: 'danger', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
            await APIRequest('GET', 'documentsService')
                .then((res) => {
                    if (res.data.success) {
                        let filter = res.data.data.filter(el => el?.idUser?.id === JSON.parse(localStorage.getItem('user'))?.id);
                        setResultDocs(filter)
                    } else {
                        toast.current.show({ severity: 'danger', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        trovaRisultato();
        dispatch(resetPathCrumb());
    }, [dispatch]);

    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };

    const onTemplateUpload = (e) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Caricato' });
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileRef && fileRef.current ? fileRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <div className="flex items-center gap-3 ml-auto">
                    <span>{formatedValue} / 1 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex items-center flex-wrap">
                <div className="flex items-center" style={{ width: '40%' }}>
                    <span className="flex flex-col text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex items-center flex-col">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Carica file
                </span>
            </div>
        );
    };

    const customUploadHandler = async (file) => {
        if (file) {
            setTitleDoc(file.files[0].name.split(".")[0])

            setFileUpload(file.files[0])
            setFileBuffer(file.files[0]);  // Save the file content for later upload confirmation
        }
    };

    const handleConfirmUpload = async () => {
        if (fileBuffer) {
            const formData = new FormData();
            formData.append('file', new Blob([fileBuffer], { type: fileUpload.type }), fileUpload.name);
            formData.append('document_type', resultsTypeDocs.find(el => el.name === "Curriculum").id);
            formData.append('metadata', "{}")
            formData.append('description', "")
            formData.append('title', `${titleDoc}`)
            formData.append('url', "/")
            formData.append('idUser', JSON.parse(localStorage.getItem('user'))?.id)
            formData.append('path', "/")

            await APIRequest('POST', 'documentsService', formData)
                .then(res => {
                    if (res.data.success) {
                        let _resultDocs = [...resultsDocs]
                        _resultDocs.push(res.data.data)
                        setResultDocs(_resultDocs)
                        toast.current.show({ severity: 'success', summary: 'Ottimo', detail: 'Il file è stato importato con successo', life: 3000 });
                    } else {
                        toast.current.show({ severity: 'danger', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                })
                .catch(e => {
                    console.log(e);
                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile importare il file. Messaggio errore: ${e.response?.data !== undefined ? e.response?.data : e.message}`, life: 3000 });
                });
        } else {
            toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Seleziona almeno un file prima di confermare`, life: 3000 });
        }
    };

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn rounded-full border border-blue-500 p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success rounded-full border border-green-700 p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger rounded-full border border-red-700 p-button-outlined' };

    return (
        <>
            <BackgroundAnimation />
            <div className="flex flex-col h-full relative">
                <Toast ref={toast} />
                <HeadPage />
                <div className='flex flex-row justify-center items-center w-full h-screen'>
                    <div className={`overflow-y-scroll flex flex-col items-center ${resultsDocs.find(el => el?.document_type?.name === "Curriculum" || el?.document_type?.name === "Contratto") ? "justify-start" : ""} min-[900px]:gap-20 p-6 items-center backdrop-blur-sm bg-gray-100/30 shadow-md min-[900px]:w-2/3 w-full min-[900px]:h-2/3 h-full rounded-lg`}>
                        <div className='min-[900px]:w-2/3 w-full flex flex-col items-center '>
                            <div className={`flex flex-row items-center shadow-md rounded-lg min-[500px]:w-4/5 w-full ${resultsDocs.find(el => el?.document_type?.name === "Curriculum" || el?.document_type?.name === "Contratto") ? "" : "min-[500px]:mt-16"}`}>
                                <div className='flex flex-row justify-center items-center bg-yellow-300/70 backdrop-blur-sm p-6 h-full rounded-l-lg'><i className='pi pi-exclamation-triangle text-3xl'></i></div>
                                <div className='p-6 w-full flex flex-row items-center justify-start'>
                                    {resultsDocs.find(el => el?.document_type?.name === "Curriculum" || el?.document_type?.name === "Contratto") ?
                                        <p>Sei in attesa della risposta...</p>
                                        :
                                        <div className='flex flex-col'>
                                            <p className='text-xl text-start'>Invia Curriculum e attendi risposte</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {!resultsDocs.find(el => el?.document_type?.name === "Curriculum") ? (
                            <>
                                <div className=' flex flex-col'>
                                    <div className='w-full text-start py-2 flex flex-row items-center gap-2 text-md font-semibold'>Seleziona i file da importare</div>
                                    <Tooltip target=".custom-choose-btn" content="Seleziona" position="bottom" />
                                    <Tooltip target=".custom-upload-btn" content="Carica" position="bottom" />
                                    <Tooltip target=".custom-cancel-btn" content="Rimuovi" position="bottom" />

                                    <FileUpload ref={fileRef} name="demo[]" url="/api/upload" accept=".PDF" maxFileSize={1000000}
                                        onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                                        headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                                        chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions}
                                        customUpload uploadHandler={customUploadHandler}
                                    />
                                    <div className='w-full text-start text-sm p-2'>Solo file con estensione .pdf</div>
                                    <Button type="button" label="Invia documenti" className="buttonCustom" onClick={handleConfirmUpload} />
                                </div>
                            </>
                        ) : (
                            <>
                                <svg class="hourglass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 206" preserveAspectRatio="none">
                                    <path class="middle" d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z" />
                                    <path class="outer" d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z" />
                                </svg>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default PageCandidato;
