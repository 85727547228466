import React, { useEffect, useRef, useState } from 'react';
import HeadPage from '../../components/headPage';
import { Toast } from 'primereact/toast';
import CustomDataTable from '../../components/customDataTable';
import { APIRequest } from '../../services/axios';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import Profilo from '../../components/profilo';
import { resetPathCrumb } from '../../store/actions/actions';
import { useDispatch } from 'react-redux';
import { useWindowSize } from '../../utils/utils';

function Recruiting() {
    const toast = useRef(null);
    const dt = useRef(null);
    const [results, setResults] = useState(null);
    const [result, setResult] = useState(null);
    const [result2, setResult2] = useState(null);
    const [resultDialog, setResultDialog] = useState(null);
    const [resultDialog2, setResultDialog2] = useState(null);
    const dispatch = useDispatch()
    const [width] = useWindowSize();

    useEffect(() => {
        async function trovaRisultato() {
            await APIRequest("GET", `recruitmentService/`)
                .then(res => {
                    if(res.data.success) {
                        setResults(res.data.data)
                    } else {
                        toast.current.show({ severity: 'danger', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                }).catch((e) => {
                    console.log(e)
                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti!', detail: "Non è stato possibile caricare i documenti", life: 3000 });
                })
        }
        trovaRisultato();
        dispatch(resetPathCrumb())
    }, [dispatch])

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const openDialog = (rowData) => {
        setResult(rowData)
        setResultDialog(true)
    }
    const userCard = (rowData) => {
        setResult2(rowData)
        setResultDialog2(true)
    }
    const downloadDocument = (rowData) => {
        console.log(rowData.idDocument.url)
    }

    const fields = [
        {
            field: "data",
            header: "Data",
            body: "dataFormat",
            sortable: true,
            showHeader: true,
        },
        {
            field: "firstName",
            header: "Nome",
            body: "firstName",
            sortable: true,
            showHeader: true,
        },
        {
            field: "lastName",
            header: "Cognome",
            body: "lastName",
            sortable: true,
            showHeader: true,
        },
        {
            field: "role",
            header: "Ruolo",
            body: "roleTemplate",
            sortable: true,
            showHeader: true,
        },
        {
            field: "status",
            header: "Stato",
            body: "statusTemplate",
            showHeader: true,
        },
    ];
    const actionFields = [
        {
            name: "Vedi documento",
            icon: <i className="pi pi-file" />,
            handler: openDialog,
        },
        {
            name: "Vedi scheda",
            icon: <i className="pi pi-user" />,
            handler: userCard,
        },
        {
            name: "Download",
            icon: <i className="pi pi-download" />,
            handler: downloadDocument,
        }
    ];

    const header = (
        <div className="flex flex-row justify-between items-center">
            <Button className="buttonCustom" label={`${width > 340 ? "Esporta CSV" : ""}`} type="button" icon="pi pi-download" rounded onClick={() => exportCSV(false)} />
        </div>
    );

    return (
        <div className="flex flex-col h-full relative">
            <Toast ref={toast} />
            <HeadPage />
            <div className={`flex-grow transition-all duration-300 p-3 min-[900px]:px-10`}>
                <CustomDataTable
                    dataKey="id"
                    loading={!results}
                    refer={dt}
                    value={results}
                    actionFields={actionFields}
                    fields={fields}
                    showHeader={true}
                    header={header}
                />
            </div>
            <Dialog visible={resultDialog} className="w-auto h-auto" header={"Dettagli documento"} onHide={() => setResultDialog(false)}>
                <div className='flex flex-col items-center'>
                    <div className='text-3xl font-semibold'>{result?.idDocument?.title}</div>
                    <div className='mt-5 text-lg '>{result?.idDocument?.description}</div>
                </div>
            </Dialog>
            <Dialog visible={resultDialog2} className="w-3/4 h-auto" header={"Dettagli documento"} onHide={() => setResultDialog2(false)}>
                <Profilo result={result2} />
            </Dialog>
        </div>
    );
}

export default Recruiting;
