import React, { useState } from "react";
import { AuthContext } from "../App";

export function AuthProvider({ children }) {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [isLogin, setIsLogin] = useState(true);

  const signin = async (user, callback) => {
    try {
      console.log("Signin called with user:", user); // Debug
      setUser(user.username);
      setToken(localStorage.getItem('token'));
      setRole(user.role.label);
      setIsLogin(false);
      console.log("State after signin:", { user: user.username, token: localStorage.getItem('token'), role: user.role.label }); // Debug
      return callback();
    } catch (error) {
      console.error("Signin error:", error); // Debug
      throw new Error("Signin failed");
    }
  };

  const signout = (callback) => {
    setUser(null);
    setToken(null);
    setRole(null);
    setIsLogin(true);
    localStorage.setItem('token', '');
    localStorage.setItem('user', '');
    return callback();
  };

  const value = { token, signin, signout, user, role, isLogin };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}