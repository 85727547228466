import React from 'react';

const ErrorDialog = ({ message, onClose }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 z-50">
            <div className="bg-white rounded-lg shadow-lg p-8 w-2/3 text-center">
                <h2 className="text-2xl font-bold mb-4">Error</h2>
                <p className="mb-4">{message}</p>
                <button
                    onClick={onClose}
                    className="bg-customTeal hover:bg-customTealHover text-white font-bold py-2 px-4 rounded"
                >
                    OK
                </button>
            </div>
        </div>
    );
};

export default ErrorDialog;
